import {NativeModules} from 'react-native';
import {LOG_SSO} from './logging';
import Base64 from '../utils/Base64';

const Aes = NativeModules.Aes;

const generateKey = (password, salt, cost, length) => Aes.pbkdf2(password, salt, cost, length);

export function writeAutoRemovableFile(fileName, removeAfterMs, content) {
  LOG_SSO.debug(
    `writeAutoRemovableFile call with file name ${fileName} and timeout ${removeAfterMs}. Calling native code...`,
  );
  NativeModules.ParentNativeTools.delayedRemove(fileName, removeAfterMs, content);
}

export async function encodePassword(passwordToEncode, privateKey) {
  const ivHex = await Aes.randomKey(16); // returns bytesToHex
  const salt = randomString(16);
  const key = await generateKey(privateKey, salt, 1000, 256); // returns bytesToHex
  const cipher = await Aes.encrypt(passwordToEncode, key, ivHex); // return Base64
  return ivHex + '.' + Base64.btoa(salt) + '.' + cipher;
}

export async function decodePassword(passwordToDecode, privateKey) {
  if (!passwordToDecode) {
    LOG_SSO.warn('No password input provided for decoding');
    return null;
  }
  const parts = passwordToDecode.split('.');
  if (!parts || parts.length !== 3) {
    LOG_SSO.warn('Unexpected format of password to decode');
    return null;
  }
  const ivHex = parts[0];
  const salt = Base64.atob(parts[1]);
  const cipher = parts[2];
  const key = await generateKey(privateKey, salt, 1000, 256); // returns bytesToHex
  return await Aes.decrypt(cipher, key, ivHex);
}

export const randomString = (n) => {
  const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const values = new Uint8Array(n);
  crypto.getRandomValues(values);
  return Array.from(values, (v) => chars[v % chars.length]).join('');
};
